import { CampaignStatus } from "@/models";
import { TrackedEventName } from "@/third-party/tracking";
import Tracked from "@components/Tracked";
import {
  ContactedIcon,
  ConversationIcon,
  MatchIcon,
  ScheduledIcon,
} from "@components/icons";
import WorldSvg from "@components/icons/people.svg";
import PageTitle from "@components/layout/page/PageTitle";
import { useAppContext } from "@context/AppContext";
import { useCampaignContext } from "@context/CampaignContext";
import { useConversationsContext } from "@context/ConversationsContext";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab, TextField } from "@mui/material";
import { useCreateCampaignRequest } from "@services/campaigns/createCampaignRequest";
import { useEffect, useMemo, useState } from "react";
import CampaignConversations from "./CampaignConversations";
import CampaignMatches from "./CampaignMatches";
import ContactedMembers from "./ContactedMembers";
import ScheduledMembers from "./ScheduledMembers";

enum TargetingTab {
  MATCHES = "MATCHES",
  SCHEDULED = "SCHEDULED",
  CONTACTED = "CONTACTED",
  CONVERSATIONS = "CONVERSATIONS",
}

const CampaignTargeting = () => {
  // App Context
  const { profile } = useAppContext();

  // Campaign Context
  const {
    campaign,
    isSearchingProspects,
    totalContactedMembers,
    totalScheduledMembers,
    isPatchingCampaign,
    searchProspectsAndEstimate,
    patchCampaign,
  } = useCampaignContext();

  // Conversations Context
  const { totalConversations, fetchTotalConversations, fetchConversations } =
    useConversationsContext();

  useEffect(() => {
    if (!totalConversations) {
      fetchTotalConversations();
      fetchConversations();
    }
  }, [fetchTotalConversations, fetchConversations, totalConversations]);

  // Target Description
  const [targetingRequest, setTargetingRequest] = useState<string>("");

  // Campaign Request
  const [createCampaignRequest, { loading: isCreatingCampaignRequest }] =
    useCreateCampaignRequest();

  // Handle update targeting
  const isUpdatingTargeting = useMemo(() => {
    return isCreatingCampaignRequest || isPatchingCampaign;
  }, [isSearchingProspects, isCreatingCampaignRequest, isPatchingCampaign]);

  // Disable update targeting
  const disableUpdateTargeting = useMemo(() => {
    return (
      !targetingRequest ||
      isUpdatingTargeting ||
      targetingRequest === campaign?.targetingRequest
    );
  }, [targetingRequest, isSearchingProspects, campaign?.targetingRequest]);

  // Initial target description
  useEffect(() => {
    if (!campaign?.targetingRequest) return;

    setTargetingRequest(campaign.targetingRequest);
  }, [campaign?.targetingRequest]);

  // Handle tabs change
  const [targetingTabValue, setTargetingTabValue] = useState(
    TargetingTab.MATCHES
  );

  // Mark contacted as active tab if the campaign is inactive
  useEffect(() => {
    if (campaign?.status === CampaignStatus.INACTIVE) {
      setTargetingTabValue(TargetingTab.CONTACTED);
    }
  }, [campaign?.status]);

  const handleTargetingTabChange = (
    event: React.SyntheticEvent,
    newValue: TargetingTab
  ) => {
    setTargetingTabValue(newValue);
  };

  const handleUpdateTargeting = () => {
    if (!targetingRequest) return;
    if (disableUpdateTargeting) return;
    if (!campaign?.id) return;
    if (!profile?.lawyer?.id) return;

    createCampaignRequest({
      lawyerId: profile?.lawyer?.id,
      description: targetingRequest,
    })
      .then((campaignRequests) => {
        if (!campaignRequests) return;
        if (!campaignRequests.length) return;

        const primaryCampaignRequest = campaignRequests[0];
        return patchCampaign({
          campaignId: campaign?.id,
          suggestedTargetingId: primaryCampaignRequest?.suggestedTargeting?.id,
          targetingRequest,
        });
      })
      .then(() => {
        // Update the search
        searchProspectsAndEstimate();
      });
  };

  return (
    <Stack
      spacing={2}
      sx={{
        ".MuiTabPanel-root": {
          p: 0,
        },
      }}
    >
      <Stack spacing={3} direction="column" alignItems={"flex-start"}>
        <PageTitle
          title="Target Companies"
          //       subtitle="Example: A UK company selling a software product to the financial
          // services sector"
        />
        <TextField
          fullWidth
          label="Target Description"
          multiline
          rows={4}
          disabled={isUpdatingTargeting}
          value={targetingRequest}
          onChange={(e) => setTargetingRequest(e.target.value)}
          variant="outlined"
        />
        <Box textAlign={"left"} width={"100%"}>
          <LoadingButton
            variant="contained"
            disabled={disableUpdateTargeting}
            onClick={() => handleUpdateTargeting()}
            loading={isUpdatingTargeting}
          >
            Update Targeting
          </LoadingButton>
        </Box>
      </Stack>
      <Box mt={3} />
      <PageTitle
        title="Your Audience"
        subtitle="We will automatically contact your matches and let you know when they reply."
        icon={<img src={WorldSvg} alt="World" height={64} />}
      />
      <TabContext value={targetingTabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tracked onChange={{ name: TrackedEventName.TARGETING_TAB_CHANGED }}>
            <TabList
              onChange={handleTargetingTabChange}
              aria-label="Targeting tabs"
              scrollButtons
              allowScrollButtonsMobile
              variant="scrollable"
            >
              <Tab
                label="Matches"
                value={TargetingTab.MATCHES}
                icon={<MatchIcon />}
                iconPosition={"start"}
              />
              <Tab
                label={`Scheduled`}
                value={TargetingTab.SCHEDULED}
                icon={<ScheduledIcon />}
                iconPosition={"start"}
              />
              <Tab
                label={`Contacted`}
                value={TargetingTab.CONTACTED}
                icon={<ContactedIcon />}
                iconPosition="start"
              />
              <Tab
                label={`Conversations`}
                value={TargetingTab.CONVERSATIONS}
                icon={<ConversationIcon />}
                iconPosition={"start"}
              />
            </TabList>
          </Tracked>
        </Box>
        <TabPanel value={TargetingTab.MATCHES}>
          <CampaignMatches />
        </TabPanel>
        <TabPanel value={TargetingTab.SCHEDULED}>
          <ScheduledMembers />
        </TabPanel>
        <TabPanel value={TargetingTab.CONTACTED}>
          <ContactedMembers />
        </TabPanel>
        <TabPanel value={TargetingTab.CONVERSATIONS}>
          <CampaignConversations />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default CampaignTargeting;
