import { ROUTE_CONVERSATION, getRoute } from "@/AuthenticatedAppRoutes";
import {
  CampaignMember,
  DISPLAYED_VISIBILITY_STATES,
  FREE_VISIBILITY_STATES,
  OutreachMessage,
  VisibilityState,
} from "@/models";
import { TrackedEventName, track } from "@/third-party/tracking";
import Blurred from "@components/Blurred";
import ContactAvatar from "@components/ContactAvatar";
import { CampaignIcon } from "@components/icons";
import {
  Badge,
  Box,
  Card,
  CardActionArea,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { getMessageDisplayDate } from "@utils/date";
import { scrambleWords } from "@utils/text";
import { useMemo } from "react";
import { ConversationUpdatePayment } from "../conversation/ConversationUpdatePayment";
import FreeLeadChip from "../conversation/FreeLeadChip";
import InvoiceLink from "../conversation/InvoiceLink";
import ConversationFeedback from "./ConversationFeedback";
import ConversationMeetingChip from "./ConversationMeetingChip";

type ConversationProps = {
  conversation: CampaignMember;
};
const Conversation = ({ conversation }: ConversationProps) => {
  const contact = conversation?.contact;

  const messages: Array<OutreachMessage> | null = useMemo(() => {
    const { emailMessages, linkedinMessages } = conversation || {};
    const messages = [
      ...(emailMessages || []),
      ...(linkedinMessages || []),
    ].filter(Boolean);

    messages.sort(
      (a, b) =>
        new Date(a.sendAt || a.sentTime || new Date()).getTime() -
        new Date(b.sendAt || b.sentTime || new Date()).getTime()
    );

    return messages;
  }, [conversation]);

  const lastReply = useMemo(() => {
    const replies = messages.filter((message) => !message.isLawyer);
    return replies[replies.length - 1];
  }, [messages]);

  // Some settings
  const isVisible = useMemo(() => {
    return (
      conversation.visibility &&
      DISPLAYED_VISIBILITY_STATES.includes(conversation.visibility)
    );
  }, [conversation.visibility]);

  const isBlurred = useMemo(() => {
    return conversation.visibility === VisibilityState.BLURRED;
  }, [conversation.visibility]);

  const isUnread = useMemo(() => {
    return !lastReply?.lawyerViewedTime;
  }, [lastReply?.lawyerViewedTime]);

  const isFree = useMemo(() => {
    return conversation.visibility
      ? FREE_VISIBILITY_STATES.includes(conversation.visibility) ||
          conversation.visibility.includes("free")
      : false;
  }, [conversation.visibility]);

  // First 100 characters of the content
  const contentPreview = useMemo(() => {
    if (!lastReply) {
      return "";
    }

    if (lastReply?.content) {
      const content = lastReply.content?.slice(0, 100);
      return !isBlurred ? content : scrambleWords(content);
    }
  }, [lastReply, isBlurred]);

  const campaignMemberMeetingId = useMemo(() => {
    return conversation.meetings?.[0]?.id;
  }, [conversation.meetings]);

  const charge = useMemo(() => {
    return conversation.charges?.[0];
  }, [conversation.charges]);

  return isVisible ? (
    <Box position={"relative"}>
      {isBlurred && <ConversationUpdatePayment />}
      <Blurred isBlurred={isBlurred}>
        <Card
          sx={{
            boxShadow: "none",
            border: "none",
            m: 0,
            backgroundColor: isUnread ? "background.info" : "transparent",
          }}
        >
          <CardActionArea
            disabled={isBlurred}
            href={getRoute(ROUTE_CONVERSATION, {
              id: conversation?.id,
            })}
            sx={{
              p: 2,
              minHeight: 130,
            }}
            onClick={(e) => {
              track({
                name: TrackedEventName.CONVERSATION_VIEWED,
                props: {
                  conversationId: conversation?.id,
                },
              });
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Badge
                color="warning"
                variant="dot"
                invisible={!isUnread}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  ".MuiBadge-badge": {
                    height: 10,
                    width: 10,
                    borderRadius: "50%",
                  },
                  ".MuiAvatar-root": {
                    borderColor: "warning.main",
                    borderWidth: isUnread ? 4 : 0,
                    borderStyle: "solid",
                  },
                }}
              >
                <ContactAvatar contact={contact} size={60} />
              </Badge>
              <Stack
                direction="column"
                spacing={0}
                flexGrow={1}
                alignItems={"flex-start"}
              >
                {isFree ? (
                  <FreeLeadChip visibility={conversation.visibility} />
                ) : null}

                {!!lastReply && (
                  <Typography
                    variant="caption"
                    color={"text.secondary"}
                    whiteSpace={"nowrap"}
                    sx={{
                      alignSelf: "flex-start",
                    }}
                  >
                    {getMessageDisplayDate(lastReply)}
                  </Typography>
                )}
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  flexWrap={"wrap"}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom={false}
                    whiteSpace={"nowrap"}
                  >
                    {contact?.firstName} {contact?.lastName}
                  </Typography>
                  <Typography variant="caption" color={"text.secondary"}>
                    {`${contact?.title || ""}${contact?.title ? " @ " : ""}${
                      contact?.company?.name || ""
                    }`}
                  </Typography>
                </Stack>
                {!!lastReply?.campaign?.name && (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    color={"text.secondary"}
                  >
                    <CampaignIcon fontSize="small" color="action" />
                    <Typography
                      variant="caption"
                      color={"text.secondary"}
                      whiteSpace={"nowrap"}
                      sx={{
                        alignSelf: "flex-start",
                      }}
                    >
                      {lastReply?.campaign?.name}
                    </Typography>
                  </Stack>
                )}

                <Box mt={2} />
                <Typography variant="body2">
                  {contentPreview}{" "}
                  {contentPreview !== lastReply?.content && "..."}
                </Typography>
                {campaignMemberMeetingId && (
                  <Box mt={2}>
                    <ConversationMeetingChip />
                  </Box>
                )}
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                flexWrap={"nowrap"}
                display={{
                  xs: "none",
                  sm: "block",
                }}
              >
                <ConversationFeedback campaignMemberId={conversation.id} />
                {conversation?.id && (
                  <InvoiceLink invoiceUrl={charge?.stripeInvoiceUrl} />
                )}
              </Stack>
            </Stack>
          </CardActionArea>
        </Card>
      </Blurred>

      <Divider />
    </Box>
  ) : null;
};

export default Conversation;
