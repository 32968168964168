import config from "@/config";
import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { normalizeGraphqlResult } from "@utils/graphql";

const GRAPHQL_URL = [config.GRAPHQL_URL.replace(/\/$/, ""), "graphql"].join("/");

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

function fetchWithTimeout(uri: any, options = {}, time: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    fetch(uri, options).then(
      (response) => {
        clearTimeout(timer);
        resolve(response);
      },
      (err) => {
        clearTimeout(timer);
        reject(err);
      }
    );
  });
}

const httpLink = new BatchHttpLink({
  uri: GRAPHQL_URL,
  batchMax: 10,
  batchInterval: 1000,
  fetch: (uri, options) => fetchWithTimeout(uri, options, 120000),
});

// Add authorization header
const authLink = setContext((_, { headers }) => {
  const extraHeaders: Record<string, string> = {};
  if (config.GRAPHQL_API_KEY) {
    extraHeaders['x-api-key'] = config.GRAPHQL_API_KEY;
  }
  return {
    headers: {
      ...headers,
      ...extraHeaders,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Accept-Encoding": "gzip, deflate, br",
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const responseLink = new ApolloLink((operation, forward) => {
  // You can modify the response before it's returned
  return forward(operation).map((response) => {
    // Your logic to intercept and modify the response
    // For example, checking for errors or logging
    return normalizeGraphqlResult(response);
  });
});

function createApolloClient() {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    defaultOptions,
  });
}

export default createApolloClient;
