import { ConversationIcon } from "@components/icons";
import { useConversationsContext } from "@context/ConversationsContext";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";

export default function CampaignConversationsLabel() {
  const { totalConversations, fetchTotalConversations } =
    useConversationsContext();

  useEffect(() => {
    fetchTotalConversations();
  }, [fetchTotalConversations]);

  return (
    <>
      {!!totalConversations && (
        <Stack direction={"row"} spacing={1} alignItems="center" py={1}>
          <ConversationIcon fontSize="small" color="primary" />
          <Typography
            variant="caption"
            color={"text.secondary"}
            whiteSpace={"nowrap"}
          >
            {totalConversations} conversations
          </Typography>
        </Stack>
      )}
    </>
  );
}
