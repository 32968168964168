import { ROUTE_DASHBOARD } from "@/AuthenticatedAppRoutes";
import config from "@/config";
import ControlledBox from "@components/ControlledBox";
import Page from "@components/layout/page/Page";
import PageContent from "@components/layout/page/PageContent";
import { useAppContext } from "@context/AppContext";
import { Box, Typography } from "@mui/material";
import { useAuthenticateCalendar } from "@services/lawyers/authenticateCalendar";
import * as CronofyElements from "cronofy-elements";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AuthenticateCalendarPage() {
  const { lawyerId, isCalendarConnected, fetchProfile } = useAppContext();

  const [searchParams] = useSearchParams();
  const [
    authenticateCalendar,
    { loading: isAuthenticatingCalendar, error: authenticateCalendarError },
  ] = useAuthenticateCalendar();

  const [code, setCode] = useState(searchParams.get("code"));

  useEffect(() => {
    const paramCode = searchParams.get("code");
    if (paramCode) {
      setCode(paramCode);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!code) {
      return;
    }

    if (!lawyerId) {
      return;
    }

    authenticateCalendar({
      code,
      lawyerId,
    }).then(() => {
      fetchProfile?.();
    });
  }, [code, lawyerId]);

  const navigate = useNavigate();

  // This ensures that we wait for the element to be added to the DOM before initializing Cronofy
  // Without this cronofy is not robust enough to handle the element not being present
  useEffect(() => {
    if (isCalendarConnected) {
      navigate(ROUTE_DASHBOARD);
      return;
    }

    const targetElementId = "cronofy-calendar-sync";

    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const targetElement = document.getElementById(targetElementId);
          if (targetElement) {
            CronofyElements.CalendarSync({
              target_id: targetElementId,
              data_center: "uk",

              authorization: {
                redirect_uri: "https://web.kular.ai/meetings/authenticate",
                client_id: config.CRONOFY_CLIENT_ID,
                scope: "read_write",
                avoid_linking: true,
              },
            });
            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [isCalendarConnected]);

  return (
    <Page hideTodoList>
      <PageContent>
        <ControlledBox
          loading={isAuthenticatingCalendar}
          error={authenticateCalendarError?.message}
        >
          <Typography variant="h6">Connect your Calendar</Typography>
          <Typography variant="body1">
            Connect or re-connect your calendar to enable your leads to book
            meetings with you.
          </Typography>
          <Box mt={2} />
          <div id="cronofy-calendar-sync"></div>
        </ControlledBox>
      </PageContent>
    </Page>
  );
}
