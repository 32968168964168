import { useUIMessageContext } from "@context/UIMessageContext";
import CopyIcon from "@mui/icons-material/FileCopy";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckSingleIcon } from "./icons";

type CopyToClipboardButtonProps = {
  text: string;
} & React.ComponentProps<typeof Button>;

const CopyToClipboardButton = ({
  text,
  children,
  ...buttonProps
}: CopyToClipboardButtonProps) => {
  const [copied, setCopied] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { showSuccessMessage } = useUIMessageContext();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    showSuccessMessage("Copied to clipboard");

    setTimer(
      setTimeout(() => {
        setCopied(false);
      }, 1000)
    );
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (
    <Button
      size="small"
      className="copy-to-clipboard"
      onClick={handleCopy}
      disabled={copied}
      startIcon={!copied ? <CopyIcon /> : <CheckSingleIcon color="success" />}
      {...buttonProps}
    >
      {children || "Copy"}
    </Button>
  );
};

export default CopyToClipboardButton;
