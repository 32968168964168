import { useFilterCampaignMembersLazyQuery } from "@/graphql/generated";
import { CampaignMember, CampaignMemberStatus } from "@/models";
import usePersonalisationStats, {
  UsePersonalisationStatsResponse,
} from "@hooks/usePersonalisationStats";
import { normalizeGraphqlResult } from "@utils/graphql";
import { UUID } from "crypto";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useCampaignsContext } from "./CampaignsContext";

type StatisticsContextProps = {
  campaignId?: UUID | null;
  personalisationStatistics: UsePersonalisationStatsResponse["stats"];
  isLoadingPersonalisationStats: boolean;
  hasNoPersonalisationStats: boolean;
};

const StatisticsContext = createContext<StatisticsContextProps>({
  campaignId: null,
  personalisationStatistics: {},
  isLoadingPersonalisationStats: false,
  hasNoPersonalisationStats: false,
});

type StatisticsProviderProps = {
  children: ReactNode;
  campaignId?: UUID;
};

export const StatisticsProvider = ({
  children,
  campaignId,
}: StatisticsProviderProps) => {
  // Fetch campaign members
  const [filterCampaignMembers, { data: memberships }] =
    useFilterCampaignMembersLazyQuery();
  const { campaignIds } = useCampaignsContext();

  useEffect(() => {
    const _campaignIds = campaignId ? [campaignId] : campaignIds;

    if (!!_campaignIds?.length) {
      filterCampaignMembers({
        variables: {
          campaignIds,
          statuses: [
            CampaignMemberStatus.COMPLETED,
            CampaignMemberStatus.CONTACTED,
            CampaignMemberStatus.MEETING_BOOKED,
            CampaignMemberStatus.LAWYER_HANDLING,
            CampaignMemberStatus.REPLIED,
            CampaignMemberStatus.SCHEDULED_OUTREACH,
            CampaignMemberStatus.SCHEDULING,
            CampaignMemberStatus.WARM_REPLY,
          ],
          offset: 0,
          first: 1000,
        },
      });
    }
  }, [campaignIds, campaignId]);

  // Use contactIds to fetch personalisation stats
  const contactIds: Array<UUID> | null = useMemo(() => {
    if (!memberships) return null;

    const _memberships = normalizeGraphqlResult(memberships).members;

    return (
      _memberships?.map((membership: CampaignMember) => membership.contactId) ||
      null
    );
  }, [memberships]);

  const {
    fetch: _fetchPesonalisationStats,
    stats: personalisationStats,
    isLoading: isLoadingPersonalisationStats,
    hasNoData: hasNoPersonalisationStats,
  } = usePersonalisationStats({});

  useEffect(() => {
    if (contactIds) {
      _fetchPesonalisationStats(contactIds);
    }
  }, [contactIds]);

  return (
    <StatisticsContext.Provider
      value={{
        campaignId,
        personalisationStatistics: personalisationStats,
        isLoadingPersonalisationStats,
        hasNoPersonalisationStats,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

export const useStatisticsContext = () => {
  return useContext(StatisticsContext);
};
