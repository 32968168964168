import { ROUTE_CAMPAIGN_CREATE } from "@/AuthenticatedAppRoutes";
import { CampaignMember } from "@/models";
import ControlledBox from "@components/ControlledBox";
import EmptyMessageBox from "@components/EmptyMessageBox";
import { AddIcon } from "@components/icons";
import { useCampaignsContext } from "@context/CampaignsContext";
import { useConversationsContext } from "@context/ConversationsContext";
import { Button, Collapse, Stack } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import Conversation from "./Conversation";

type ConversationsProps = {
  conversations: Array<CampaignMember> | null;
};

const Conversations = ({ conversations }: ConversationsProps) => {
  const { isLoading: isLoadingConversations } = useConversationsContext();
  const { campaigns, isLoading: isLoadingCampaigns } = useCampaignsContext();

  const isLoading = useMemo(() => {
    return isLoadingConversations || isLoadingCampaigns;
  }, [isLoadingConversations, isLoadingCampaigns]);

  // Empty Message states
  const hasNoCampaigns = useMemo(() => {
    return !!campaigns && campaigns.length === 0 && !isLoading;
  }, [campaigns, isLoading]);

  const hasNoSearchResults = useMemo(() => {
    return !!conversations && conversations.length == 0 && !isLoading;
  }, [conversations, isLoading]);

  return (
    <ControlledBox
      loading={isLoading}
      p={0}
      minHeight={300}
      loadingMessage="Loading conversations"
    >
      <Collapse in={hasNoCampaigns && hasNoSearchResults}>
        <EmptyMessageBox
          title="You don't have any conversations yet."
          subtitle="Create and activate your campaigns to start conversations."
          actionButton={
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              startIcon={<AddIcon />}
              to={ROUTE_CAMPAIGN_CREATE}
            >
              Draft New Campaign
            </Button>
          }
        />
      </Collapse>
      <Collapse in={!!(!hasNoCampaigns && hasNoSearchResults && !isLoading)}>
        <EmptyMessageBox title="No Conversations Yet" />
      </Collapse>
      <Stack direction="column" spacing={0} mb={2}>
        {conversations?.map((conversation) => {
          return (
            <div key={conversation?.id}>
              <Conversation conversation={conversation} />
            </div>
          );
        })}
      </Stack>
    </ControlledBox>
  );
};

export default Conversations;
